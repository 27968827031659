.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*Contact page styling*/

.contact-btn {
  background-color: var(--accent);
  border: 0;
  outline: none;
  padding: 9px 15px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 15px;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 15px;
}

.contact-para {

  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6em;
  overflow-x: hidden;
}

.sub-header h2 {
  color: #999696;
  font-size: 18px;
  margin-bottom: 20px;
  padding: 0;
  margin: 0 0 20px 0;
  font-weight: 600;
  line-height: 1.3em;
  font-family: "Poppins", sans-serif;
}

.contact-benefits {
  margin-block: 51px;
}

.contact-benefits h2,
.contact-next h2 {
  color: white;
  font-size: 20px;
}

.contact-benefits .benefits-items {
  display: flex;
  /* gap: 20px; */
  flex-wrap: wrap;
  justify-content: space-between;
}

.benefits-items .item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 13px;

}

.item span {
  background-color: var(--accent);
  text-align: center;
  justify-content: center;
  display: flex;

  /* vertical-align: super; */
  align-items: center;
  padding: 3px;
  font-weight: bold;
  border-radius: 50%;
}
.contact-next{
  margin-bottom: 25px;
}
.next-menu{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.next-item {
  display: flex;
  gap: 17px;
  align-items: center;
}
.next-item span{
  font-size: 33px;
  font-weight: 600;
  color: white;
  display: contents;
}
.next-item p{
  margin-bottom: 0 !important;
}
/*Contact form styling*/
.form-wrapper{
  background-color: white;
  border-radius: 8px;
  position: relative;
}

.form-header{
  padding: 25px 0px;
 
}
hr.form-divider{
  height: 3px;
   
    padding: 0;
    overflow: visible;
    border: none;
    background-color: var(--accent);
    
    text-align: center;
}
hr::after{
  content: '\2936';
  position: relative;
  top: -11px;
  z-index: 1;
  padding: 0 16px;
  background: #fff;
  color: black;
  font-size:45px
}
.divider-bg{
 width: 400px;
 position: relative;
 height: 200px;
 background-repeat: no-repeat;
}
.form-header h1{
  font-size:22px;
  color: var(--accent);
  text-align: center;
  font-weight: 600;
  margin-bottom: 0 !important;
 
}

.contact-form{
  padding: 23px 30px;
}
.cs-primary_color{
  color: black;
  font-weight: bold;
}
.cs-form_field{
  color: black !important;
}
